body{
  background-color:#fcfcfc !important;
}
.error {
  color: red;
}

.react-confirm-alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: #9999 !important;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  opacity: 0;
  -webkit-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -moz-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -o-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}

.custom-ui {
  text-align: center;
  width: 500px;
  padding: 40px;
  background: #ffff;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
  color: 'black';
}

.custom-ui > h1 {
  margin-top: 0;
  color: 'red'
}

.custom-ui > button {
  width: 160px;
  padding: 10px;
  border: 1px solid black;
  margin: 10px;
  cursor: pointer;
  background: none;
  color: #fff;
  font-size: 14px;
}

.badge.badge-red {
  background-color: #dc3545 !important;
  border: 1px solid #dc3545;
}

.badge.badge-blue {
  background-color: #007bff !important;
  border: 1px solid #007bff;
}

.edit-account {
  float: left;
  cursor: pointer;
}

.search-account {
  float: left;
  cursor: pointer;
  margin: 0px 10px 0px 15px;
}

.delete-account {
  cursor: pointer;
}

.toggle.ios, .toggle-on.ios, .toggle-off.ios {
  border-radius: 20px;
}

.toggle.ios .toggle-handle {
  border-radius: 20px;
}

.search-invoice {
  margin-left: 35px;
}

.pay-button {
  float: right;
}

.form-check-input-custome {
  position: inherit !important;
  margin-left: 25px !important;
}

.view-all {
  float: right;
  text-decoration: underline;
}
.divider-register {
  text-align: center;
  width: 100%;
  margin: 20px 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
}
.divider-register:after, .divider-register:before {
  content: "";
  height: 1px;
  background: #d8d9dc;
  -ms-flex: 1;
  flex: 1;
}
.divider-text-register {
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-preferred-size: 33px;
  flex-basis: 33px;
  width: 33px;
  height: 33px;
  border-radius: 50%;
  margin: 0;
  border: 1px solid #d8d9dc;
  color: #84888e;
  font-size: 11px;
  line-height: 11px;
  font-weight: 400;
}

.main-app .register-main {
  border: 0;
  margin: 40px auto;
  background-color: #fff;
  border-radius: 15px;
  text-align: center;
  box-sizing: border-box;
  padding: 40px;
  position: relative;
  box-shadow: 1px 2px 20px 0px #cbc0ff  
}
.register-main .signup-page-header .label-on-top {
    font-size: 19px;
    line-height: 22px;
    text-align: right;
    width: 115px;
    height: 65px;
    background-color: #FF9200;
    color: #fff;
    padding: 10px 25px 10px 0;
    box-sizing: border-box;
    border-radius: 0 13px 13px 0;
    position: absolute;
    top: 10px;
    right: -16px;
}

.register-main .signup-page-header .label-on-top:before {
  content: "";
    display: block;
    height: 65px;
    width: 40px;
    background: transparent url(http://zoomfieldservices.com/images/label_bg.svg) 0 0 no-repeat;
    background-size: 139px 66px;
    position: absolute;
    left: -25px;
    top: 0;
}
.signup-page-header h2 strong {
  color: #FF9200;
}
.start-trial-title {
  text-align: left;
  margin-bottom: 2rem;
}