// Here you can add other styles
.sidebar {
  //background: #5f7174 !important
  background: #1b2b40 !important;
}

.sidebar .nav-dropdown.open {
  background: #2b61a6 !important;
  // background: #5f7174 !important
}

.nav-link .nav-dropdown-toggle {
  background: #2b61a6 !important;
  // background: #5f7174 !important
}

.sidebar .nav-link.active {
  // background: #A2EFE8!important;
  background: #2b61a6 !important;
  color: #ffffff !important;
}

.sidebar .nav-link:hover {
  // background: #A2EFE8 !important;
  background: #2b61a6 !important;
  color: #ffffff !important;
}

// Auto Complete
.auto-complete {
  position: relative;

  ul {
    position: absolute;
    list-style-type: none;
    padding: 0;
    z-index: 1;
    background: white;
    border: 1px solid #a0bad0;
    width: 100%;
    height: 220px;
    overflow-y: scroll;

    li {
      width: 100%;
      padding: 5px 10px;
      border-bottom: 1px solid #80808047;

      &:hover {
        background-color: #0095ff63;
      }
    }
  }
}

.table-loader {
  border-top: 16px solid blue;
  border-right: 16px solid green;
  border-bottom: 16px solid red;
  border-left: 16px solid pink;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 9999;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.demo-header{
  background-color: #2b61a6;
}
